<script lang="ts" setup>
defineProps<{
  link: string;
  image: string;
  title: string;
  description?: string;
  large?: boolean;
}>();
</script>

<template>
  <NuxtLink
    :to="link"
    target="_blank"
    external
    class="newsCard"
    :class="{ newsCard_large: large }"
  >
    <NuxtImg
      format="webp"
      :sizes="large ? IMAGE_SIZES.newsLarge : IMAGE_SIZES.newsSmall"
      :src="image"
      :alt="title"
      class="newsCard__image"
    />
    <p class="newsCard__title">
      {{ title }}
    </p>
    <p class="newsCard__description">
      {{ description }}
    </p>
  </NuxtLink>
</template>

<style lang="scss">
.newsCard {
  width: 337px;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #121212;
  display: block;

  @include mq('sm') {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
  }

  &_large {
    width: 574px;

    @include mq('sm') {
      width: 100%;
    }
  }

  &__image {
    width: 100%;
    height: 337px;
    object-fit: cover;
    display: block;
    border-radius: 10px;
  }

  &__title {
    margin: 12px 0 0;
  }

  &__description {
    margin-top: 3px;
    opacity: 0.4;
  }
}
</style>
