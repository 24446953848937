<script lang="ts" setup>
defineProps<{
  title: string;
  image: string;
  description?: string;
  actions: {
    label: string;
    download?: string;
    to?: string;
    outlined?: boolean;
  }[];
  overflow?: boolean;
}>();
</script>

<template>
  <div class="baseBannerCard">
    <NuxtImg
      format="webp"
      :sizes="IMAGE_SIZES.fullscreen"
      :src="image"
      :alt="title"
      class="baseBannerCard__background"
    />

    <div class="baseBannerCard__content">
      <div class="baseBannerCard__info">
        <h2 class="baseBannerCard__title">
          {{ title }}
        </h2>
        <p v-if="description" class="baseBannerCard__description">
          {{ description }}
        </p>
      </div>

      <div class="baseBannerCard__actions">
        <template v-for="(item, index) in actions">
          <a
            v-if="item.download"
            :key="`a_${index}`"
            :href="item.to || ''"
            :download="item.download"
          >
            <BaseButton
              :outlined="item.outlined"
              :class="{ baseBannerCard__button_overflow: overflow }"
              mobile-size="xs"
            >
              {{ item.label }}
            </BaseButton>
          </a>
          <NuxtLink v-else :key="`NuxtLink_${index}`" :to="item.to || ''">
            <BaseButton
              :outlined="item.outlined"
              :class="{ baseBannerCard__button_overflow: overflow }"
              mobile-size="xs"
            >
              {{ item.label }}
            </BaseButton>
          </NuxtLink>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.baseBannerCard {
  position: relative;
  flex: 1;

  &__background {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 10px;
    object-fit: cover;
  }

  &__content {
    padding: 30px 25px 27px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    min-height: 807px;

    @include mq('lg') {
      min-height: 520px;
    }

    @include mq('sm') {
      flex: initial;
      min-height: 400px;
    }
  }

  &__info {
    max-width: 549px;
    width: 100%;
  }

  &__title {
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.2px;
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 27px;

    @include mq('sm') {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #ffffff;
    margin: 0;
  }

  &__actions {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }

  &__button_overflow {
    width: 100%;
  }
}
</style>
