<script lang="ts" setup>
import type { MediaType } from '~/api/common';

defineProps<{
  media: MediaType;
}>();
</script>

<template>
  <div class="baseMediaCard">
    <NuxtImg
      format="webp"
      :src="media.image"
      :alt="media.title"
      :sizes="IMAGE_SIZES.media"
      class="baseMediaCard__image"
    />

    <div class="baseMediaCard__content">
      <span>{{ media.category }}</span>

      <div>
        <span>{{ media.title }}</span>
        <p v-if="media.description" class="baseMediaCard__description">
          {{ media.description }}
        </p>
        <NuxtLink v-if="media.url" :to="media.url" target="_blank" external>
          <BaseButton size="s">
            {{ media.action }}
          </BaseButton>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.baseMediaCard {
  border-radius: 10px;
  height: 100%;
  overflow: hidden;

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 10px;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 19px 21px 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #ffffff;

    * {
      width: 100%;
      display: block;
    }
  }

  &__description {
    margin-top: 12px;
    margin-bottom: 24px;
  }
}
</style>
